// extracted by mini-css-extract-plugin
export var clientQuotesMobileDev = "F_gp";
export var devBenefitBussinesMobileDev = "F_gk";
export var domainExpertiseMobileDev = "F_gf";
export var mobileAppDevTechnologies = "F_gb";
export var mobileAppDevTechnologies__wrapper = "F_gc";
export var mobileAppProjLogoSection = "F_gm";
export var ourKeyPrinciplesMobileDev = "F_gj";
export var serviceMobileTestimonialPost = "F_gh";
export var servicesCardBorderIconMobileDevSection = "F_f8";
export var servicesCardWithTopBorderSectionMobileApp = "F_f9";
export var servicesProcessMobileDevSection = "F_gg";
export var successStoriesMobileSection = "F_gn";
export var weBuildProjectsDifferentSection = "F_gd";
export var whyChooseServicesMobile = "F_gl";